import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '@app-shared/services';
import { HttpUtilHelper } from '@app-shared/helpers/http-util-helper';
import { GetDcCollectionByCriteriaPagedRequest } from './models/GetDcCollectionByCriteriaPagedRequest';
import {
  PricingAdjustmentFactorsApiRequest,
  UpdateFNMABestEffortsAdjustmentFactorsApiRequest
} from './models/PricingAdjustmentFactorsApiRequest';

import { GetDcCollectionByCriteriaPagedResponse } from './models/GetDcCollectionByCriteriaPagedResponse';
import { GetDeliveryCommitmentApiRequest } from './models/GetDeliveryCommitmentApiRequest.model';
import { GetBestEffortsDeliveryDataApiRequest } from './models/GetBestEffortsDeliveryDataApiRequest';
import { GetDeliveryCommitmentApiResponse } from './models/GetDeliveryCommitmentApiResponse.model';
import { GetBestEffortsDeliveryDataApiResponse } from './models/GetBestEffortsDeliveryDataApiResponse';
import { GetParticipationsApiRequest } from './models/GetParticipationsApiRequest.modal';
import { GetParticipationsApiResponse } from './models/GetParticipationsApiResponse.modal';
import { GetSubProductsForDCApiRequest } from './models/GetSubProductsForDCApiRequest';
import { GetSubProductsForDCApiResponse } from './models/GetSubProductCodeForDCApiResponse';
import { GetProductsByMasterCommitmentIDApiRequest } from './models/GetProductsByMasterCommitmentIDApiRequest';
import { GetProductsByMasterCommitmentIDApiResponse } from './models/GetProductsByMasterCommitmentIDApiResponse';
import { UpdateDeliveryCommitmentApiResponse } from './models/UpdateDeliveryCommitmentApiResponse';
import { UpdateDeliveryCommitmentApiRequest } from './models/UpdateDeliveryCommitmentApiRequest';

import { GetExtensionsApiRequest } from './models/GetExtensionsApiRequest';
import { GetExtensionsApiResponse } from './models/GetExtensionsApiResponse';
import { IGetDCReductionApiRequest } from './models/GetDCReductionApiRequest';
import { IGetDCReductionApiResponse } from './models/GetDCReductionApiResponse';
import { CalculateExtensionFeeApiRequest } from './models/CalculateExtensionFeeApiRequest';
import { CalculateExtensionFeeApiResponse } from './models/CalculateExtensionFeeApiResponse';
import { ExtendDeliveryCommitmentApiRequest } from './models/ExtendDeliveryCommitmentApiRequest';
import { ExtendDeliveryCommitmentApiResponse } from './models/ExtendDeliveryCommitmentApiResponse';
import { CalculatePairoffFeeApiRequest } from './models/CalculatePairoffFeeApiRequest.model';
import { CalculatePairoffFeeApiResponse } from './models/CalculatePairoffFeeApiResponse.model';
import { MPFPricingParameterResponse } from '../administration/models/MPFPricingParameterResponse';
import { GetFNMABestEffortsAdjustmentFactorsApiResponse } from '../administration/models/GetFNMABestEffortsAdjustmentFactorsApiResponse';
import { GetAdjustmentFactorsRequest } from './models/GetAdjustmentFactors.request.model';
import { AdjustmentFactorsResponseModel } from './models/AdjustmentFactors.Response.model';
import { GetReduceDeliveryCommitmentApiRequest } from './models/GetReduceDeliveryCommitmentApiRequest';
import { GetReduceDeliveryCommitmentApiResponse } from './models/GetReduceDeliveryCommitmentApiResponse';
import { CheckAccountingProcessCodeApiRequest } from './models/CheckAccountProcessingCodeApiRequest';
import { CheckAccountingProcessCodeApiResponse } from './models/CheckAccountingProcessCodeApiResponse';
import { CreateDcApiRequest } from './models/CreateDcApiRequest';
import { CreateDcApiResponse } from './models/CreateDcApiResponse';
import { SendDeliveryCommitmentExpNoticesResponse } from './models/SendDeliveryCommitmentExpNoticesResponse';

@Injectable()
export class DeliveryCommitmentService {
  private API_PATH =  `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
  private BUILT_API_PATH = `${this.API_PATH}/api/deliverycommitment`;
  private API_SERVICE_PATH = `${this.BUILT_API_PATH}/deliverycommitment`;
  private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  private websocketOptions(connectionId: string) { return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'connectionid': `${connectionId}` }) } }
  private reqstr: string;
  constructor(private http: HttpClient) { }


  getDcCollectionByCriteriaPaged(req: GetDcCollectionByCriteriaPagedRequest): Observable<GetDcCollectionByCriteriaPagedResponse> {
    return this.http.get<GetDcCollectionByCriteriaPagedResponse>(`${this.API_SERVICE_PATH}/${"getDcCollectionByCriteriaPaged".toLowerCase()}?${HttpUtilHelper.ConvertComplexObjectQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  UpdateMPFPricingParameters(req: PricingAdjustmentFactorsApiRequest) {
    return this.http.post<number>(`${this.API_SERVICE_PATH}/${"UpdateMPFPricingParameters".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  GetDeliveryCommitment(req: GetDeliveryCommitmentApiRequest) {
    return this.http.get<GetDeliveryCommitmentApiResponse>(`${this.API_SERVICE_PATH}/${"getDeliveryCommitment".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetBestEffortsDeliveryData(req: GetBestEffortsDeliveryDataApiRequest) {
    return this.http.get<GetBestEffortsDeliveryDataApiResponse>(`${this.API_SERVICE_PATH}/${"GetBestEffortsDeliveryData".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }






  GetParticipations(req: GetParticipationsApiRequest) {
    return this.http.get<GetParticipationsApiResponse>(`${this.API_SERVICE_PATH}/${"GetParticipations".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetSubProductsForDC(req: GetSubProductsForDCApiRequest) {
    return this.http.get<GetSubProductsForDCApiResponse>(`${this.API_SERVICE_PATH}/${"getSubProductsForDC".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetProductsByMasterCommitmentID(req: GetProductsByMasterCommitmentIDApiRequest) {
    return this.http.get<GetProductsByMasterCommitmentIDApiResponse>(`${this.API_SERVICE_PATH}/${"GetProductsByMasterCommitmentID".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }
  UpdateDeliveryCommitment(req: UpdateDeliveryCommitmentApiRequest) {

    return this.http.post<UpdateDeliveryCommitmentApiResponse>(`${this.API_SERVICE_PATH}/${"UpdateDeliveryCommitment".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }


  GetExtensions(req: GetExtensionsApiRequest) {
    return this.http.get<GetExtensionsApiResponse>(`${this.API_SERVICE_PATH}/${"getExtensions".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  GetReductions(req: IGetDCReductionApiRequest) {
    return this.http.get<IGetDCReductionApiResponse>(`${this.API_SERVICE_PATH}/${"GetReductions".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  CalculateExtensionFee(req: CalculateExtensionFeeApiRequest) {
    return this.http.post<CalculateExtensionFeeApiResponse>(`${this.API_SERVICE_PATH}/${"CalculateExtensionFee".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  ExtendDeliveryCommitment(req: ExtendDeliveryCommitmentApiRequest) {
    return this.http.post<ExtendDeliveryCommitmentApiResponse>(`${this.API_SERVICE_PATH}/${"ExtendDeliveryCommitment".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  CalculatePairOffFee(req: CalculatePairoffFeeApiRequest) {

    return this.http.post<CalculatePairoffFeeApiResponse>(`${this.API_SERVICE_PATH}/${"CalculatePairOffFee".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  GetPricingAdjustmentFactorsAndFees() {
    return this.http.get<MPFPricingParameterResponse>(`${this.API_SERVICE_PATH}/${"GetPricingAdjustmentFactorsAndFees".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }
  GetFNMABestEffortsAdjustmentFactors() {
    return this.http.get<GetFNMABestEffortsAdjustmentFactorsApiResponse>(`${this.API_SERVICE_PATH}/${"GetFNMABestEffortsAdjustmentFactors".toLowerCase()}`, this.options)
      .pipe(map(response => response));
  }
  UpdateFNMABestEffortsAdjustmentFactors(req: UpdateFNMABestEffortsAdjustmentFactorsApiRequest) {
    return this.http.post<UpdateDeliveryCommitmentApiResponse>(`${this.API_SERVICE_PATH}/${"UpdateFNMABestEffortsAdjustmentFactors".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  /*
  User Story 255192: Ang v15 upgrade -> Due to an unintended JavaScript comparison in HttpUtilHelper.ConvertQueryString() where "0 == []" would evaluate as TRUE, 
  any input parameters getting passed to these HTTP GET APIs with a value of 0 or 0.0 would then always get ignored and not translated into the resulting API
  query string. To preserve the original API query string that is getting passed in as part of the API call, any explicit 0's in the API calls are removed so that
  they do not appear in the resulting query string either.
  */
  GetAdjustmentFactors(req: GetAdjustmentFactorsRequest): Observable<AdjustmentFactorsResponseModel> {
    return this.http.get<AdjustmentFactorsResponseModel>(`${this.API_SERVICE_PATH}/${"GetAdjustmentFactors".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }



  ReduceDeliveryCommitment(req: GetReduceDeliveryCommitmentApiRequest) {
    return this.http.post<GetReduceDeliveryCommitmentApiResponse>(`${this.API_SERVICE_PATH}/${"ReduceDeliveryCommitment".toLowerCase()}`, req, this.options)
      .pipe(map(response => response));
  }

  checkAccountingProcessCode(req: CheckAccountingProcessCodeApiRequest): Observable<CheckAccountingProcessCodeApiResponse> {
    return this.http.get<CheckAccountingProcessCodeApiResponse>(`${this.API_SERVICE_PATH}/${"checkAccountingProcessCode".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
      .pipe(map(response => response));
  }

  createDeliveryCommitment(connectionId: string, req: CreateDcApiRequest) {
    return this.http.post<CreateDcApiResponse>(`${this.API_SERVICE_PATH}/${"CreateDeliveryCommitment".toLowerCase()}`, req, this.websocketOptions(connectionId))
      .pipe(map(response => response));
  }

  SendDeliveryCommitmentExpirationNotices(): Observable<SendDeliveryCommitmentExpNoticesResponse> {
    return this.http.get<SendDeliveryCommitmentExpNoticesResponse>(`${this.API_SERVICE_PATH}/${"SendDeliveryCommitmentExpirationNotices".toLowerCase()}`, this.options)
        .pipe(map(response => response));
}

}

